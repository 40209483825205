var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"PolicyPatchEditor tw-flex tw-items-center tw-mb-2"},[_c('h1',{staticClass:"tw-pb-0"},[_vm._v("Packages Ready For Approval")]),_c('policy-help',{attrs:{"type":'Manual Approval'}})],1),_c('v-divider',{staticClass:"tw-mb-2"}),_c('div',{staticClass:"tw-my-4 tw-p-4 tw-w-full"},[_c('v-card-text',[_c('div',{staticClass:"tw-flex"},[_c('ax-sidebar-layout',{attrs:{"value":_vm.queryState.filter_panel_open},on:{"input":function (value) { return _vm.updateQueryState(
                { filter_panel_open: value },
                { bypassUiUrlUpdate: true, bypassApiUrlUpdate: true }
              ); }},scopedSlots:_vm._u([{key:"sidebar",fn:function(){return [_c('div',{staticClass:"tw-w-full"},[_c('div',{staticClass:"tw-w-full tw-flex tw-justify-center"},[_c('ax-button',{attrs:{"small":"","mode":"secondary"},on:{"click":_vm.clearFilters}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.resetFilters'))+" ")])],1),_c('v-expansion-panels',{staticClass:"tw-flex-col tw-justify-start",attrs:{"value":[0, 1],"multiple":""}},[(_vm.queryState)?_c('ax-accordion-filter',{attrs:{"title":"Operating System","number-of-active-filters":_vm.queryState.os_family.length,"filter-max-length":_vm.osFilters.length}},_vm._l((_vm.osFilters),function(item){return _c('ax-checkbox',{key:item.value,attrs:{"input-value":_vm.queryState.os_family,"label":item.name,"value":item.value,"hide-details":""},on:{"change":function (os_family) { return _vm.updateQueryState(
                          { os_family: os_family },
                          { debounceTime: _vm.DEFAULT_CHECKBOX_DEBOUNCE_TIME }
                        ); }}})}),1):_vm._e(),(_vm.queryState)?_c('ax-accordion-filter',{attrs:{"title":"Status","number-of-active-filters":_vm.queryState.status.length,"filter-max-length":_vm.statusFilters.length}},_vm._l((_vm.statusFilters),function(item){return _c('ax-checkbox',{key:item.value,attrs:{"input-value":_vm.queryState.status,"label":item.name,"value":item.value,"hide-details":""},on:{"change":function (status) { return _vm.updateQueryState(
                          { status: status },
                          { debounceTime: _vm.DEFAULT_CHECKBOX_DEBOUNCE_TIME }
                        ); }}})}),1):_vm._e()],1)],1)]},proxy:true}])}),_c('div',{staticClass:"tw-w-full tw-pl-6"},[_c('v-row',[_c('v-col',{staticClass:"tw-pr-0",attrs:{"cols":"12","md":"6"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateQueryState({ display_name: _vm.search })}}},[_c('ax-text-field',{staticClass:"tw-w-full mb-0",attrs:{"prepend-inner-icon":_vm.mdiMagnify,"placeholder":"Search Packages","autocomplete":"off","clearable":"","hide-details":""},on:{"click:clear":function($event){return _vm.updateQueryState({ display_name: undefined })}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1),_c('ax-table',{staticClass:"tw-w-full",attrs:{"items":_vm.packages,"headers":_vm.computedTableHeaders,"footer-props":_vm.tableFooter,"server-items-length":_vm.totalPackages,"options":_vm.tableState,"item-key":"keyId","loading-text":"Loading packages...","no-data-text":"No packages found","show-select":""},on:{"update:options":function($event){_vm.tableState=$event}},scopedSlots:_vm._u([{key:"item.software.display_name",fn:function(ref){
                        var item = ref.item;
return [_c('span',{staticClass:"tw-capitalize"},[_vm._v(_vm._s(item.software.display_name)+" - "+_vm._s(item.software.version))])]}},{key:"item.software.os_family",fn:function(ref){
                        var item = ref.item;
return [_c('ax-os-icon',{staticClass:"tw-text-lg",attrs:{"os-family":item.software.os_family}})]}},{key:"item.status",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.status))])]}},{key:"item.manual_approval_time",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.manual_approval_time))])]}},{key:"item.policy",fn:function(ref){
                        var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                  name: _vm.PolicyRouteNames.PolicyForm,
                  params: { policyId: item.policy.id },
                  query: { o: _vm.queryState.o },
                },"title":item.policy.name}},[_vm._v(" "+_vm._s(item.policy.name)+" ")])]}}]),model:{value:(_vm.selectedPackages),callback:function ($$v) {_vm.selectedPackages=$$v},expression:"selectedPackages"}})],1)],1),_c('div',{staticClass:"tw-flex tw-justify-end tw-mt-8"},[_c('ax-button',{staticClass:"tw-mr-3",attrs:{"mode":"secondary"},on:{"click":function($event){return _vm.approveOrRejectPolicies(false)}}},[_vm._v(" Reject ("+_vm._s(_vm.selectedPackages.length)+") ")]),_c('ax-button',{attrs:{"mode":"primary"},on:{"click":function($event){return _vm.approveOrRejectPolicies(true)}}},[_vm._v(" Approve ("+_vm._s(_vm.selectedPackages.length)+") ")])],1)])],1),_c('div',{staticClass:"tw-flex tw-justify-end tw-my-8"},[(_vm.queryState && _vm.queryState.frompage)?_c('ax-button',{staticClass:"tw-mr-3",attrs:{"mode":"secondary","to":{
        path: _vm.queryState.frompage,
        query: { o: _vm.queryState.o },
      }}},[_vm._v(" Back ")]):_vm._e(),(_vm.queryState && _vm.queryState.pid[0])?_c('ax-button',{attrs:{"mode":"primary","to":{
        name: _vm.PolicyRouteNames.PolicyForm,
        params: { policyId: _vm.queryState.pid[0] },
        query: { o: _vm.queryState.o },
      }}},[_vm._v(" Edit Policy ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }